<template>
  <section
    class="box-height bonusCalculationSsc scollDom"
    style="margin-bottom: 70px; padding: 0 15px"
    id="bonusCalculationSsc"
  >
    <div
      class="box-title"
      style="padding-bottom: 20px; border: 1px #e5e5e5 solid"
    >
      <el-row
        :gutter="24"
        v-if="!successFul"
        style="margin-left: 0; margin-right: 0"
      >
        <el-col :span="24" class="customTag" style="padding: 0">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane name="first">
              <span class="tagW" slot="label">测算申请</span>
            </el-tab-pane>
            <el-tab-pane name="second">
              <span class="tagW" slot="label">核对测算表（系统生成）</span>
            </el-tab-pane>
            <el-tab-pane name="three">
              <span class="tagW" slot="label">待发奖金表</span>
            </el-tab-pane>
            <el-tab-pane name="four">
              <span class="tagW" slot="label">已发奖金表</span>
            </el-tab-pane>
          </el-tabs>
        </el-col>
        <el-col v-if="activeName == 'first'">
          <div style="width: 360px; margin: 50px auto">
            <el-steps :active="active" finish-status="success" align-center>
              <el-step title="选择测算年份"></el-step>
              <el-step title="选择测算周期"></el-step>
            </el-steps>
          </div>
          <div class="cardList" v-if="active == 0">
            <div v-for="(i, index) in cardList" :key="index" class="c_col">
              <div
                v-if="index < 2"
                id="triangle-topright"
                :class="index == 0 ? 'bodTop' : 'bodTop1'"
                style="display: block; z-index: 99"
              >
                <span v-if="index == 0">今年</span>
                <span v-if="index == 1">去年</span>
              </div>
              <p class="title">{{ i.year }}年</p>
              <p class="tabHref subtext" @click="goMeasurements(i.year)">
                点击进入测算页面 >
              </p>
              <p
                class="tabHref bottomtxt"
                @click="goDifferenceLastYear(i.activeName)"
              >
                去年差额
              </p>
            </div>
          </div>
          <div v-if="active == 1">
            <el-form
              @submit.native.prevent
              :model="from"
              ref="from"
              label-width="90px"
              class="addForm"
            >
              <el-row :gutter="24">
                <el-col :offset="7" :span="10">
                  <el-form-item
                    label="回款时间："
                    prop="accountDate"
                    :rules="[
                      {
                        required: true,
                        message: '请选择回款时间',
                        trigger: 'change',
                      },
                    ]"
                  >
                    <el-date-picker
                      v-model="from.accountDate"
                      value-format="yyyy-MM-dd"
                      format="yyyy-MM-dd"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      style="width: 100%"
                      :picker-options="accountDateOpt"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :offset="7" :span="10">
                  <el-form-item
                    label="开票时间："
                    prop="invoiceDate"
                    :rules="[
                      {
                        required: true,
                        message: '请选择开票时间',
                        trigger: 'change',
                      },
                    ]"
                  >
                    <div style="display: flex; align-items: center">
                      <el-date-picker
                        v-model="from.invoiceDate"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        style="width: 100%"
                        :picker-options="invoiceDateOpt"
                      >
                      </el-date-picker>
                      <el-tooltip class="item" effect="dark" placement="bottom">
                        <template slot="content">
                          自动带出开票时间内在职、或开票时间内有开票业绩（包括离职）<br />的顾问，可在下一步手动增加或删除
                        </template>
                        <i
                          class="iconfont icon icon-wenhao-xiangsu"
                          style="
                            margin-left: 10px;
                            font-size: 18px;
                            position: absolute;
                            right: -30px;
                            font-weight: 400;
                            color: #999;
                          "
                        ></i>
                      </el-tooltip>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div style="text-align: center; margin: 50px 0">
              <el-button size="small" @click="previousStep">
                < 返回上一步</el-button
              >
              <el-button
                size="small"
                type="primary"
                @click="submitApplication"
                :loading="applicationloading"
                >提交测算申请</el-button
              >
            </div>
          </div>
        </el-col>
        <el-col v-if="activeName !== 'first' && !noData" style="padding: 0">
          <el-radio-group
            v-model="chioceTable"
            v-if="activeName == 'second'"
            @change="handleTableClick"
            style="padding: 0 15px"
          >
            <el-radio-button
              v-for="(i, index) in tableList"
              :key="index"
              :label="i.id"
              size="mini"
              >{{ i.name }}</el-radio-button
            >
          </el-radio-group>
          <el-divider
            v-if="activeName == 'second'"
            style="margin: 5px !important"
          ></el-divider>
          <div
            v-if="activeName == 'second'"
            class="flexRow"
            style="
              justify-content: space-between;
              margin-bottom: 10px;
              padding: 0 15px;
            "
          >
            <div class="flexRow">
              <el-button
                size="mini"
                @click="batchDelete"
                :disabled="chioceIds.length == 0"
                >批量删除</el-button
              >
              <el-button
                size="mini"
                @click="handelMeasurements"
                :disabled="chioceIds.length == 0"
                >批量重新测算</el-button
              >
              <el-button
                v-if="!isAddConsultant"
                size="mini"
                type="primary"
                @click="addConsultant"
                >+增加顾问</el-button
              >
              <el-button
                size="mini"
                @click="downLoadTable"
                :loading="downLoadLoading"
                >导出</el-button
              >
              <div v-if="isAddConsultant" style="margin-left: 8px">
                <el-cascader
                  style="width: 240px"
                  clearable
                  size="mini"
                  filterable
                  :show-all-levels="false"
                  :options="options"
                  collapse-tags
                  ref="dataRef"
                  v-model="userIds"
                  :props="cascaderProp"
                  @change="getSearch"
                  :filter-method="filterFun"
                ></el-cascader>
                <el-button
                  size="mini"
                  type="primary"
                  @click="handelMeasurements"
                  style="margin-left: 10px"
                  >开始测算</el-button
                >
              </div>
            </div>
            <p>
              共
              <span style="color: orange; font-weight: 600">{{
                tableData.userCount
              }}</span>
              位顾问
            </p>
          </div>
          <div
            v-if="activeName == 'three' || activeName == 'four'"
            style="margin-bottom: 10px; padding: 0 15px"
          >
            <el-form :model="filter" ref="filter" label-width="100px">
              <el-row>
                <el-form-item
                  label="奖金表名："
                  prop="tableIds"
                  class="fl"
                  label-width="80px"
                >
                  <el-select
                  style="width:240px"
                    v-model="filter.tableIds"
                    placeholder="请选择"
                    filterable
                    clearable
                    collapse-tags
                    multiple
                    @change="submitForm"
                    popper-class="bonusCalculationSscSelect"
                  >
                    <el-option
                      v-for="(item, index) in tableList"
                      :key="'name_' + index"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  v-if="activeName == 'three'"
                  label="预计发放时间："
                  prop="planPayDate"
                  class="fl"
                  label-width="120px"
                >
                  <el-date-picker
                    v-model="filter.planPayDate"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    @change="submitForm()"
                    style="width:240px"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item
                  v-if="activeName == 'four'"
                  label="实际发放时间："
                  prop="payDate"
                  class="fl"
                  label-width="120px"
                >
                  <el-date-picker
                    v-model="filter.payDate"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    @change="submitForm()"
                    style="width:240px"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item
                  label="所属年份："
                  prop="yearValue"
                  class="fl"
                  label-width="90px"
                >
                  <el-date-picker
                    v-model="filter.yearValue"
                    value-format="yyyy"
                    type="year"
                    :clearable="false"
                    style="width:240px"
                    placeholder="请选择所属年份"
                    @change="submitForm()"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item
                  label="确认状态："
                  prop="confirmStatuses"
                  class="fl"
                  label-width="80px"
                >
                  <el-select
                    v-model="filter.confirmStatuses"
                    placeholder="请选择"
                    filterable
                    clearable
                    collapse-tags
                    multiple
                    @change="submitForm"
                    popper-class="bonusCalculationSscSelect"
                    style="width:240px"
                  >
                    <el-option v-show="activeName=='three'"  value="TO_BE_SENT" label="待发送"></el-option>
                    <el-option v-show="activeName=='three'"  value="PENDING" label="待确认"></el-option>
                    <el-option v-show="activeName=='three'||activeName=='four'"  value="CONFIRMED" label="已确认"></el-option>
                    <el-option v-show="activeName=='four'" value="TIMEOUT_AUTO_CONFIRMED" label="超时自动确认"></el-option>
                  </el-select>
                </el-form-item>
              </el-row>
            </el-form>
            <div class="flexRow" style="justify-content: space-between">
              <div>
                <el-button
                v-if="activeName == 'three'"
                size="mini"
                @click="sendToConfirm"
                :disabled="chioceIds.length == 0"
                >发送给负责人确认</el-button
              >
                <el-button
                  v-if="activeName == 'three'"
                  size="mini"
                  @click="batchDelete"
                  :disabled="chioceIds.length == 0"
                  >批量删除</el-button
                >
                <el-button
                  v-if="activeName == 'three'"
                  size="mini"
                  @click="handelMeasurements"
                  :disabled="chioceIds.length == 0"
                  >批量重新测算</el-button
                >
                <el-button
                  size="mini"
                  type="primary"
                  v-if="activeName == 'three'"
                  @click="confirmIssuance"
                  :disabled="chioceIds.length == 0"
                  >确认发放</el-button
                >
                <el-button
                  size="mini"
                  @click="downLoadTable"
                  :loading="downLoadLoading"
                  >导出</el-button
                >
              </div>
              <el-pagination
                v-if="activeName == 'four' && filter.page.total > 0"
                :hide-on-single-page="filter.page.total > 10 ? false : true"
                style="padding: 0"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="filter.page.current"
                :page-size="filter.page.size"
                :pager-count="5"
                layout="total,prev, pager, next,sizes"
                :total="filter.page.total"
                :page-sizes="[10, 20, 50, 100]"
              ></el-pagination>
            </div>
          </div>
          <measurementsTable
            :key="measurementsTableNum"
            ref="measurementsTable"
            @measurements="handelMeasurements"
            @getChioceId="getChioceId"
            @getChoiceData='getChoiceData'
            @getPage="getPage"
            @getbonusDataTableInfo="getbonusDataTableInfo"
            @moneyComputer="moneyComputer"
            @changeOptions="changeOptions"
            :filter="filter"
            :tableId="chioceTable"
            :tableStatus="tableStatus"
            :options="options"
            style="margin-bottom: 30px"
          ></measurementsTable>

          <div
            style="
              text-align: center;
              position: fixed;
              width: 100%;
              bottom: 0;
              left: 0;
              right: 0;
              background: #fff;
              padding: 10px 0;
              z-index: 4;
              border-top: 1px #ececec solid;
            "
            v-if="activeName == 'second'"
          >
            <el-button
              type="primary"
              size="small"
              @click="(dialogVisible = true), (titleName = '保存为待发奖金表')"
              >保存为待发奖金表</el-button
            >
          </div>
        </el-col>

        <el-col class="noData" v-if="noData && activeName == 'second'">
          <i class="el-icon-warning-outline"></i>
          <p>暂无需要核对的测算表</p>
          <p>
            您可以<span class="tabHref" @click="addNewApplication"
              >创建一个测算申请</span
            >
          </p>
        </el-col>
      </el-row>
      <div class="successContent" v-if="successFul">
        <i class="el-icon-circle-check"></i>
        <p>提交成功</p>
        <p v-if="activeName == 'first'">系统测算中，请稍后.....</p>
        <p v-if="activeName == 'first'">
          测算完成后，系统将以邮件形式及时通知您！
        </p>
        <div v-if="activeName == 'second'" class="infoContent">
          <p style="font-weight: 600">奖金表基础信息</p>
          <div class="i_col">
            奖金表名称：
            <p
              style="
                max-width: 500px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              "
              :title="successInfo.bonusTableName"
            >
              {{ successInfo.bonusTableName }}
            </p>
          </div>
          <div class="i_col">
            预发放时间：
            <p>{{ successInfo.planPayDate }}</p>
          </div>
          <div class="i_col">
            待发奖金总额：
            <p>{{ successInfo.totalBonus }}元</p>
          </div>
          <div class="i_col">
            总人数：
            <p>{{ successInfo.userCount }}人</p>
          </div>
        </div>
        <div style="margin-top: 25px">
          <el-button
            size="mini"
            v-if="activeName == 'second'"
            @click="checkTab"
            style="margin-right: 10px"
          >
            查看待发奖金表
          </el-button>
          <el-button size="small" type="primary" @click="addNewApplication">
            创建一个新的测算申请
          </el-button>
        </div>
      </div>
    </div>
    <el-drawer
      :visible.sync="drawerMeasurementsVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleDrawerClose"
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">{{ yearValue }}年奖金测算</div>
      <div class="drawerCon">
        <measurementsTable
          v-if="drawerMeasurementsVisible"
          ref="measurementsTable1"
          :chioceIds="chioceIds"
          :tableId="chioceTable"
          :userIds="filter.userIds"
          :tableStatus="tableStatus"
          :recalculateReason='reason'
          @handleDrawerClose="handleDrawerClose"
        ></measurementsTable>
      </div>
    </el-drawer>
    <el-dialog
      :title="titleName"
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      append-to-body
      width="600px"
      :before-close="handleClose"
    >
      <el-form
        v-if="titleName == '保存为待发奖金表'"
        @submit.native.prevent
        :model="tablesData"
        ref="tablesData"
        label-width="120px"
      >
        <el-form-item label="待发奖金总额：">
          <p>{{ tableData.totalBonus }}元</p>
        </el-form-item>
        <el-form-item
          label="奖金表名称："
          prop="tableName"
          :rules="[
            { required: true, message: '请输入奖金表名称', trigger: 'blur' },
          ]"
        >
          <el-input
            placeholder="例如：xx年x月x日提成、xx年终奖金、xx年度奖金结算等"
            v-model="tablesData.tableName"
            style="width: 80%"
            maxlength="50"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="预计发放时间："
          prop="planPayDate"
          :rules="[
            {
              required: true,
              message: '请选择预计发放时间',
              trigger: 'change',
            },
          ]"
        >
          <el-date-picker
            v-model="tablesData.planPayDate"
            type="date"
            value-format="yyyy-MM-dd"
            style="width: 80%"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <el-form
        v-if="titleName == '确认发放'"
        @submit.native.prevent
        :model="issuanceData"
        ref="issuanceData"
        label-width="120px"
      >
        <el-form-item label="总金额：">
          <p>{{ issuanceData.totalBonus }}元</p>
        </el-form-item>
        <el-form-item
          label="实际发放时间："
          prop="planPayDate"
          :rules="[
            {
              required: true,
              message: '请选择实际发放时间',
              trigger: 'change',
            },
          ]"
        >
          <el-date-picker
            v-model="issuanceData.planPayDate"
            type="date"
            value-format="yyyy-MM-dd"
            style="width: 80%"
          >
          </el-date-picker>
        </el-form-item>
        <div v-if="pendingData.length>0">
        <p style="color:#e60012">特别提醒：以下是负责人还未确认的名单，请关注：</p>
        <el-table
          :data="pendingData"
          border
          style="width: 100%">
          <el-table-column
            prop="date"
            label="顾问"
            width="180">
            <template slot-scope="scope">
              <p :title="scope.row.userName" :style="!scope.row.userStatus&&scope.row.totalBonus>0?{ color: '#e60012' }:''">
              {{ scope.row.userName }}
            </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="totalBonus"
            label="总待发奖金"
            width="180">
          </el-table-column>
          <el-table-column
            prop="confirmStatusDesc"
            label="确认状态">
          </el-table-column>
        </el-table>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" plain size="mini" @click="handleClose"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :loading="tableLoading"
          @click="saveTable"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="tipdialogVisible"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      append-to-body
      width="600px"
    >
      <div>
        <p style="font-weight: bold; font-size: 20px">无法创建新测算表</p>
        <p style="">
          请先到 “
          <span class="tabHref" @click="goNext">核对测算表（系统生成）</span>”
          生成待发奖金表，再创建新的测算申请
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="default"
          plain
          size="mini"
          @click="tipdialogVisible = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisibleConfirm"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      append-to-body
      width="600px"
      :before-close="handleClose"
    >
    <p style='color:#e58a00'>由于团队负责人已确认本次奖金，请填写重新测算原因：</p>
    <el-input class="comfirmInput" v-model="reason" type="textarea" :rows="2" placeholder="必填项" style="margin: 10px 0;"></el-input>
    <p style='color:#999'>重新测算后将刷新当前的测算数据</p>
    <span slot="footer" class="dialog-footer">
        <el-button type="default" plain size="mini" @click="handleClose"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          @click="handelConfirmMeasurements(deletIds,reason)"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </section>
</template>

<script>
import {
  bonusCalculateSscCalculate, //测算申请
  bonusCalculateSscExistTable, //检查当年是否有测算表
  bonusDataTableOptions, //获取奖金表选项列表
  bonusDataTableInfo, //奖金表信息
  bonusDataGeneratedToPay, //生成待发奖金表
  permissionTree, //获取顾问
  bonusDataPay, //确认发放奖金
  bonusConfirmSendToConfirm
} from "../../api/api";
import measurementsTable from "../../components/report/measurementsTable";
export default {
  components: {
    measurementsTable,
  },
  data() {
    return {
      activeName: "first",
      active: 0,
      cardList: [
        {
          year: new Date().getFullYear(),
          activeName: "first",
        },
        {
          year: new Date().getFullYear() - 1,
          activeName: "second",
        },
        {
          year: new Date().getFullYear() - 2,
          activeName: "three",
        },
      ],
      from: {},
      accountDateOpt: {
        disabledDate: this.accountDisabled,
      },
      invoiceDateOpt: {
        disabledDate: this.invoiceDisabled,
      },
      successFul: false,
      noData: false,
      tableList: [],
      // tableName: "2023年奖金测算表",
      chioceTable: "",
      options: [],
      optionsCopy: [],
      userIds: [],
      isAddConsultant: false,
      drawerMeasurementsVisible: false,
      saveloading: false,
      nameList: [],
      filter: {
        tableId: null,
        planPayDate: [],
        payDate: [],
        page: {
          current: 1,
          size: 50,
          total: 0,
        },
        yearValue: null,
        calculateModelNames: [],
        confirmStatuses:[]
      },
      applicationloading: false,
      chaoiceYear: null,
      tableData: {},
      tableStatus: null,
      tableLoading: false,
      dialogVisible: false,
      tablesData: {
        tableName: null,
        planPayDate: null,
      },
      successInfo: {},
      titleName: "",
      issuanceData: {
        totalBonus: 0,
      },
      chioceIds: [],
      yearValue: null,
      downLoadLoading: false,
      measurementsTableNum: 0,
      tipdialogVisible: false,
      cascaderProp: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
        checkStrictly: false,
        // lazy: true,
        // lazyLoad: this.lazys,
      },
      dialogVisibleConfirm:false,
      isConfirm:false,
      reason:'',
      chioceData:[],
      pendingData:[]
    };
  },
  methods: {
    checkTab() {
      this.activeName = "three";
      this.successFul = false;
      this.getBonusDataTableOptions("TO_PAY");
      this.submitForm();
    },
    //禁用时间
    accountDisabled(time) {
      console.log(this.chaoiceYear);
      let start = new Date(this.chaoiceYear, 0, 1).getTime();
      return time.getTime() < start || time.getTime() > Date.now();
    },
    //禁用时间
    invoiceDisabled(time) {
      let start = new Date(this.chaoiceYear, 0, 1).getTime();
      let end = new Date(this.chaoiceYear, 11, 31).getTime();
      return time.getTime() < start || time.getTime() > end;
    },
    //奖金选项表
    getBonusDataTableOptions(status) {
      this.tableStatus = status;
      bonusDataTableOptions({ status: status }).then((res) => {
        if (res.success) {
          this.tableList = res.result;
          if (status == "GENERATED" && res.result.length == 0) {
            this.noData = true;
          } else if (status == "GENERATED" && res.result.length > 0) {
            this.chioceTable = res.result[0].id;
            this.filter.tableId = this.chioceTable;
            this.getbonusDataTableInfo();
          }
        }
      });
    },
    //奖金表信息
    getbonusDataTableInfo() {
      if(this.chioceTable){
        bonusDataTableInfo({ tableId: this.chioceTable }).then((res) => {
        if (res.success) {
          this.tableData = res.result ? res.result : {};
          this.filter.tableId = this.chioceTable;
          this.submitForm();
          this.isAddConsultant = false;
          // this.$refs.measurementsTable.loadList();
        }
      });
      }
    },
    // 顾问
    permissionTree() {
      permissionTree({
        type: "顾问",
        showLeaveStatus: true,
        showDisableUser: true,
      }).then((res) => {
        if (res.success) {
          this.options = res.result;
          // this.options = this.mapNewTree(res.result,[res.result[0].id]);
          // this.optionsCopy = res.result||[];
          this.$refs.measurementsTable.handelConsultant(this.options, true);
        }
      });
    },
    lazys(node, resolve) {
      console.log(node, resolve);
      let level = node.level;
      let result = [];
      if (node.children) {
        result = this.$refs.measurementsTable.handelConsultant(
          node.children,
          true
        );
      }
      resolve(result);
    },
    //递归顾问
    // mapNewTree(data, pidArr) {
    //   console.log(data, pidArr)
    //   let dataNew = JSON.parse(JSON.stringify(data));
    //   function mapNewArr(dataNew, pidArr) {
    //     dataNew.map((item) => {
    //       if (pidArr.indexOf(item.id) === -1) {
    //         item.idList = [item.id];
    //         item.hasChildren = true;
    //         item.children = null;
    //         return item;
    //       } else {
    //         mapNewArr(item.children, pidArr);
    //       }
    //     });
    //     return dataNew;
    //   }
    //   mapNewArr(dataNew, pidArr);
    //   return mapNewArr(dataNew, pidArr);
    // },
    addConsultant() {
      this.isAddConsultant = true;
      this.permissionTree();
    },
    changeOptions(data) {
      this.options = data;
    },
    //tab切换
    handleClick(tab) {
      this.activeName = tab.name;
      this.cleanData()
      if (tab.name == "second") {
        this.getBonusDataTableOptions("GENERATED");
      } else if (tab.name == "three") {
        this.getBonusDataTableOptions("TO_PAY");
        this.submitForm();
      } else if (tab.name == "four") {
        this.getBonusDataTableOptions("PAID");
        this.submitForm();
      }
      this.measurementsTableNum++;
    },
    // 返回上一步
    previousStep() {
      this.active = 0;
      this.cleanFrom();
    },
    cleanFrom() {
      this.from = {};
    },
    cleanData(){
      this.chioceIds=[];
      this.noData = false;
      this.filter = {
        tableId: null,
        planPayDate: [],
        payDate: [],
        page: {
          current: 1,
          size: 50,
          total: 0,
        },
        yearValue: null,
        confirmStatuses:[]
      };
    },
    // 去年差额
    goDifferenceLastYear(activeName) {
      let newpage = this.$router.resolve({
        path: "/report/differenceLastYear",
        query: { activeName: activeName },
      });
      window.open(newpage.href, "_blank");
    },
    //测算
    goMeasurements(year) {
      this.cleanFrom();
      bonusCalculateSscExistTable({ yearValue: year }).then((res) => {
        if (res.success) {
          if (res.result) {
            this.tipdialogVisible = true;
          } else {
            this.active = 1;
            this.chaoiceYear = year;
          }
        }
      });
    },
    goNext() {
      this.tipdialogVisible = false;
      this.activeName = "second";
      this.getBonusDataTableOptions("GENERATED");
    },
    //提交测算申请
    submitApplication() {
      // console.log(this.from.accountDate, this.from.invoiceDate);
      if (!this.from.accountDate) {
        this.$message.error("请选择回款时间");
        return;
      }
      if (!this.from.invoiceDate) {
        this.$message.error("请选择开票时间");
        return;
      }
      this.applicationloading = true;
      bonusCalculateSscCalculate({
        accountStartDate: this.from.accountDate ? this.from.accountDate[0] : "",
        accountEndDate: this.from.accountDate ? this.from.accountDate[1] : "",
        invoiceStartDate: this.from.invoiceDate ? this.from.invoiceDate[0] : "",
        invoiceEndDate: this.from.invoiceDate ? this.from.invoiceDate[1] : "",
      }).then((res) => {
        if (res.success) {
          this.successFul = true;
          this.cleanFrom();
        }
        this.applicationloading = false;
      });
    },
    //新建测算申请
    addNewApplication() {
      this.successFul = false;
      this.active = 0;
      this.noData = false;
      this.activeName = "first";
    },
    //核对测算表
    handleTableClick(tab) {
      this.getbonusDataTableInfo();
    },
    getSearch(val) {
      const _nodeId = this.$refs.dataRef.getCheckedNodes(true);
      // let isChecked;
      this.filter.userIds = this.userIds.map((i) => {
        return i[i.length - 1];
      });
      // this.search();
    },
    filterFun(data, value) {
      // 弹窗 - 拼音过滤
      return data.data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    handleDrawerClose() {
      let dom = document.getElementById("bonusCalculationSsc");
      dom.classList.add("scollDom");
      this.chioceIds = [];
      this.chioceData=[]
      this.reason=''
      this.isConfirm=false
      this.drawerMeasurementsVisible = false;
      this.$refs.measurementsTable.loadList();
      this.getbonusDataTableInfo();
    },
    //测算
    handelMeasurements(data) {
      if(this.isConfirm){
        this.dialogVisibleConfirm=true
      }else{
        let div =
        "<p style='font-weight:bold;color:#333;font-size:16px;'>确定重新测算？</p>" +
        "<p style='color:#e58a00'>确定重新测算后将刷新当前的测算数据</p>";
      this.$confirm(div, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "altCls",
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          let dom = document.getElementById("bonusCalculationSsc");
          dom.classList.remove("scollDom");
          this.drawerMeasurementsVisible = true;
          this.yearValue = data.yearValue
            ? data.yearValue
            : this.tableData.yearValue;
        })
        .catch(() => {});
      }
    },
    handelConfirmMeasurements(){
      if(!this.reason){
        this.$message.error('请输入原因')
        return;
      }
      let dom = document.getElementById("bonusCalculationSsc");
          dom.classList.remove("scollDom");
          this.drawerMeasurementsVisible = true;
          this.yearValue = this.tableData.yearValue;
          this.dialogVisibleConfirm=false
    },
    //保存为待发奖金表
    saveTable() {
      this.tableLoading = true;
      this.tablesData.tableId = this.tableData.id;
      let req = null;
      if (this.titleName == "确认发放") {
        this.$refs["issuanceData"].validate((valid) => {
          if (valid) {
            req = bonusDataPay({
              ids: this.chioceIds,
              payDate: this.issuanceData.planPayDate,
            });
          } else {
            this.tableLoading = false;
            return false;
          }
        });
      } else {
        req = bonusDataGeneratedToPay(this.tablesData);
      }
      req.then((res) => {
        if (res.success) {
          if (this.titleName == "确认发放") {
            this.$refs.measurementsTable.loadList();
          } else {
            this.successFul = true;
            this.successInfo = res.result;
          }
          this.cleanData()
          this.dialogVisible = false;
        }
        this.tableLoading = false;
      });
    },
    //关闭弹窗
    handleClose() {
      this.dialogVisible = false;
      this.dialogVisibleConfirm=false;
      this.isConfirm=false;
      this.reason='';
    },
    //保存
    save() {
      this.drawerMeasurementsVisible = false;
      this.$refs.measurementsTable.loadList();
    },
    //切换页数
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.filter.page.current = val;
      this.submitForm();
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.filter.page.size = val;
      this.filter.page.current = 1;
      this.submitForm();
    },
    //获取分页数据
    getPage(data) {
      this.filter.page.total = data;
    },
    //搜索
    submitForm() {
      this.$nextTick(() => {
        this.$refs.measurementsTable.loadList();
      });
    },
    //批量删除
    batchDelete() {
      this.$refs.measurementsTable.delectData(null);
    },
    //确认发放
    confirmIssuance() {
      let arr=[]
      arr=this.chioceData.filter(val=>{
        return val.confirmStatus=='TO_BE_SENT'
      })
      if(arr.length>0){
        let txt= "<p style='font-size:16px;font-weight:bold; color:#333;text-align:left;'>有未发送给负责人确认的名单，请检查 " +
          "<p style='font-size:13px;text-align:left;margin-bottom:10px'>请先发送给负责人确认，再确认发放</p>";
        this.$confirm(txt, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: "altCls",
          showClose: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          dangerouslyUseHTMLString: true,
        })
          .then(() => {
          })
          .catch(() => {});
      }else{
        this.dialogVisible = true;
      this.titleName = "确认发放";
      this.$refs.measurementsTable.getAllMoney();
      if(this.chioceData.length>0){
        let pendingData=[]
        pendingData=this.chioceData.filter(val=>{
          return val.confirmStatus=='PENDING'
        })
        let quitMoney=[]
        let onJobMoney=[]
        let quitNoMoney=[]
        let onJobNoMoney=[]
        onJobMoney=pendingData.filter(val=>{
          return val.userStatus&&val.totalBonus!==0
        })
        quitMoney=pendingData.filter(val=>{
          return !val.userStatus&&val.totalBonus!==0
        })
        onJobNoMoney=pendingData.filter(val=>{
          return val.userStatus&&val.totalBonus==0
        })
        quitNoMoney=pendingData.filter(val=>{
          return !val.userStatus&&val.totalBonus==0
        })
        this.pendingData=[...quitMoney,...onJobMoney,...onJobNoMoney,...quitNoMoney]
      }
      }
    },
    moneyComputer(data) {
      this.issuanceData.totalBonus = data.toFixed(2);
    },
    //获取选中id
    getChioceId(ids,isConfirm) {
      this.chioceIds = ids;
      this.isConfirm=isConfirm;
    },
    //获取选中数据
    getChoiceData(data){
      this.chioceData=data
    },
    //导出
    downLoadTable() {
      this.$refs.measurementsTable.downLoadTable();
    },
    //发送给负责人确认
    sendToConfirm(){
      bonusConfirmSendToConfirm({ids:this.chioceIds}).then((res) => {
        if (res.success) {
          this.$message.success("操作成功");
          this.submitForm();
        }
      });
    },
  },
};
</script>

<style lang="scss">
.bonusCalculationSsc {
  .el-step__icon {
    width: 32px;
    height: 32px;
  }
  .el-step__head.is-process {
    .el-step__icon {
      color: #fff;
      border-color: #526ecc;
      background: #526ecc;
    }
  }
  .el-step__title.is-process {
    color: #526ecc;
  }
  .el-step__head.is-success {
    .el-step__icon {
      color: #526ecc;
      border-color: #526ecc;
      background: #fff;
    }
    .el-step__line {
      background-color: #526ecc;
    }
  }
  .el-step__title.is-success {
    color: #526ecc;
  }
  .el-divider--horizontal {
    margin: 10px 0;
  }
  .el-radio-button__inner {
    height: 32px;
    line-height: 0.5;
  }
  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: #fff;
    color: #e60012;
  }
  .el-input--mini .el-input__inner {
    height: 28px !important;
  }
  .el-tag.el-tag--info{
        max-width: 120px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .el-select__tags-text{
        display: block;
        max-width: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.bonusCalculationSscSelect {
  .el-select-dropdown__item span {
    // span{
    margin-right: 20px;
    // }
  }
}

.customTag {
  position: relative;
  .el-tabs__item {
    line-height: 50px;
    height: 50px;
    font-size: 14px;
    color: #606266;
  }
  .el-tabs__nav-scroll {
    padding: 0 20px;
  }
  .el-tabs__item.is-active {
    color: #e60012 !important;
  }
}
.cardList {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 150px;
  .c_col {
    width: 300px;
    padding: 25px;
    text-align: center;
    border: #ececec 1px solid;
    border-radius: 6px 8px 8px;
    position: relative;
    overflow: hidden;
    .title {
      font-size: 28px;
      color: #333;
      line-height: 40px;
    }
    .subtext {
      font-size: 14px;
      margin: 0 0 25px;
    }
    .bottomtxt {
      font-size: 13px;
      color: #606266;
      cursor: pointer;
    }
  }
  .c_col:hover {
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  }
  div:nth-child(2) {
    margin: 0 50px;
  }
}
.successContent {
  text-align: center;
  margin: 90px 0 0;
  i {
    font-size: 60px;
    color: #42b983;
  }
  > :nth-child(2) {
    font-size: 22px;
    color: #333;
    font-weight: 700;
    margin: 10px 0 30px 0;
  }
  > :nth-child(3),
  > :nth-child(4) {
    font-size: 16px;
    color: #606266;
    margin-bottom: 8px;
  }
  > :nth-child(5) {
    font-size: 14px;
    margin: 35px 0;
  }
}
.noData {
  color: #606266;
  text-align: center;
  margin: 130px 0;
  i {
    font-size: 60px;
    color: #efa14d;
  }
  > :nth-child(2) {
    font-size: 22px;
    font-weight: 600;
    margin: 30px 0 40px;
  }
  :nth-child(3) {
    font-size: 14px;
  }
}
.flexRow {
  display: flex;
  align-items: center;
}
.drawTit {
  font-size: 22px;
  font-weight: 600;
  padding-left: 15px;
  border-left: 8px #efa14d solid;
  color: #efa14d;
  // margin-bottom: 20px;
  height: 25px;
  line-height: 25px;
}
.comfirmInput{
  .el-textarea__inner{
    padding: 5px 10px;
  }
}
</style>

<style scoped  lang="scss">
.bodTop {
  border-top: 44px solid #42b983 !important;
}
.bodTop2 {
  border-top: 44px solid #c0c4cc !important;
}
// .shadowCard:hover {
//   #triangle-topleft {
//     display: inline-block;
//   }
// }

#triangle-topright {
  display: none;
  width: 0;
  height: 0;
  right: 0;
  top: 0;
  position: absolute;
  cursor: pointer;
  border-top: 44px solid #c0c4cd;
  border-left: 44px solid transparent;
  padding-top: 3px;
  padding-left: 2px;
  span {
    color: #fff;
    right: 37px;
    position: relative;
    top: -37px;
    font-size: 12px;
    line-height: 1;
    width: 44px;
    display: block;
    transform: rotate(45deg);
  }
}
// #triangle-topright::before {
//   content: "阅";
//   color: #b7b7b7;
//   right: 2px;
//   font-family: element-icons !important;
//   position: relative;
//   top: -34px;
//   font-size: 12px;
// }
.infoContent {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  margin: 0 auto 15px;
  border: 1px #ececec solid;
  padding: 5px 15px;
  p {
    font-size: 14px;
    margin: 5px;
    text-align: left;
  }
  .i_col {
    font-size: 14px;
    margin: 5px;
    color: #999999;
    p {
      font-weight: 600;
      color: #333;
    }
  }
}
</style>
